import React, { useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import FavoriteIcon from '@mui/icons-material/Favorite';
import { ReactComponent as TropicalSkyLogo } from '../assets/logo/tropical_sky.svg';
import { colors } from '../styles/colors';
import { LeftDrawer } from './LeftDrawer';
import { useSelector, useDispatch } from 'react-redux';
import { setDrawerState } from '../features/drawer/drawerSlice';
import SearchDrawer from './RightDrawer';
import { ReactComponent as Close } from '../assets/icons/Close.svg';
import { Destinations } from './DestinationsCard';
import { HoliDayType } from './HolidayType';
import { headerHeight } from '../common/headerHeight';
import {
  Backdrop,
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  Tooltip,
  MenuItem,
  Avatar,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import UKFlag from '../assets/icons/Uk.svg';
import IrelandFlag from '../assets/icons/Ireland.svg';
import CloseIcon from '@mui/icons-material/Close';
import { setCurrentCountry } from '../features/country/country';
import { setDialogState } from '../features/dialog/dialogSlice';
import { useAuth } from '../context/AuthContext';
import { useCookies } from 'react-cookie';
import { setUserDetails } from '../features/userAuthentication/userInformation';
import Image from './Image';
import useWindowWidth from '../hooks/useWindowWidth';
import { setSelectedRegion } from '../features/commonState';
import {  phoneCodeByRegionUK } from '../constants/phoneCode';
import { phoneCodeByRegionIE } from '../constants/phoneCodeIE';

const navMainMenus = [
  {
    name: 'Holiday Types',
    component: <HoliDayType />,
    index: 1,
  },
  {
    name: 'Destinations',
    component: <Destinations />,
    index: 2,
  },
  {
    name: 'Offers & Deals',
    component: null,
    index: 3,
    link: `${process.env.REACT_APP_REDIRECT_URL}deals`
  },
  {
    name: 'Inspiration',
    component: null,
    index: 4,
    link: `${process.env.REACT_APP_REDIRECT_URL}travel-inspiration`
  },
];
function Navbar() {
  //! States
  const location = useLocation();
  const drawerState = useSelector((state: any) => state.drawer.drawerState);
  const { currentCountry } = useSelector((state: any) => state.currentCountry);
  const dialogState = useSelector((state: any) => state.dialog.dialogState);
  const { setIsUserLoggedIn } = useAuth();
  const { userDetails } = useSelector((state: any) => state.userInformation);
  const windowWidth = useWindowWidth()
  const { selectedRegion } = useSelector((state: any) => state.commonState);

  //! Hooks
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [direction, setDirection] = useState('');
  const [toggle, setToggle] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [countryChange, setCountryChange] = useState(false);
  // eslint-disable-next-line
  const [_, __, removeCookie] = useCookies(['user-email']);
  //! Variables
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (location?.search) {
      const destinationMatch = location?.search?.match(/destination=([^&]+)/);
      if (destinationMatch) {
        process.env.REACT_APP_PROJECT_COUNTRY === "ie" ? dispatch(setSelectedRegion(phoneCodeByRegionIE.find(dest => dest.TSRID == destinationMatch?.[0].split(";")[1]))) :
          dispatch(setSelectedRegion(phoneCodeByRegionUK.find(dest => dest.TSRID == destinationMatch?.[0].split(";")[1])))
      }
    }
  }, [location.search, dispatch])

  const getRoute = () => {
    const path = location.pathname;
    const pathParts = path.split('/'); // Split the path into parts

    // Get the dynamic part, which is the last segment
    const id = pathParts[pathParts.length - 1];

    if (path.includes(`/holiday-summary`)) {
      return `/holiday-search${window.location.search}`;
    } else if (path.includes(`/passenger-details`)) {
      return `/holiday-summary/${id}${window.location.search}`;
    }
    else if (path == '/booking-confirmation') {
      return '/';
    } else {
      return '/';
    }
  };
  
  const isBookingConfirmationPage = () => {
    return location.pathname === '/booking-confirmation';
  };

  //! Functions
  useEffect(() => {
    dispatch(setDrawerState({ ...drawerState, [direction]: true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  useEffect(() => {
    if (window.location.pathname === "/signin") {
      setCurrentIndex(4)
    } else if (window.location.pathname === "/forgot-password") {
      setCurrentIndex(6)
    }
  }, [window.location.pathname])

  useEffect(() => {
    setDirection('');
  }, [drawerState]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (location?: string | any) => {
    setCurrentIndex(4)
    setAnchorElUser(null);
    if (location) {
      if (location === '/signin') {
        setIsUserLoggedIn(false);
        removeCookie('user-email');
        dispatch(setUserDetails({}))
        localStorage.removeItem('userData')
      }
      navigate(location);
    }
  };

  const handleSearch: any = (text: any) => {
    console.log({ text });
  };

  const handleClickAway = () => {
    setToggle(false);
    if (window.location.pathname === "/signin") {
      setCurrentIndex(4)
    }
  };

  const handleCountryChange = () => {
    dispatch(setCurrentCountry(currentCountry === 'uk' ? 'ireland' : 'uk'));
    setCountryChange(false);
  };

  const [btnClick, setBtnClick] = useState(false);

  const handleBodyClick = (event: any) => {
    if (!event.target.closest("#callbackButton")) {
      setBtnClick(false);
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);

  const handleButtonClick = () => {
    setBtnClick(true);
  };

  const headerActionBar = (
    <>
      {countryChange ? (
        <Box
          width='254px'
          justifyContent='center'
          alignItems='center'
          height='100%'
          display={{ xs: 'flex', xl: 'none' }}
          margin='auto'
        >
          <IconButton onClick={handleCountryChange}>
            <Avatar
              alt='country flag'
              src={currentCountry === 'uk' ? IrelandFlag : UKFlag}
              sx={{ width: 16, height: 16, mr: 0.5, borderRadius: 0 }}
            />
          </IconButton>
          <IconButton onClick={() => setCountryChange(false)}>
            <CloseIcon sx={{ color: colors.basics.primary }} />
          </IconButton>
        </Box>
      ) : (
        <Box display={{ xs: 'flex', xl: 'none' }} padding='10px' justifyContent='space-between'>
          <Box display='flex' alignItems={"center"} sx={{ width: { xs: window.innerWidth < 360 ? '50%' : 'auto' } }}>
            <Box>
              <Typography variant='body1' fontSize='14px'>
                <a href={`tel:${selectedRegion?.phone || process.env.REACT_APP_CONTACT_US_NO}`} rel='nofollow' style={{ textDecoration: 'none' }}>
                <span className={`${selectedRegion?.ClassName || process.env.REACT_APP_CONTACT_US_NO}`}  style={{ color: colors.basics.primary }}>{selectedRegion?.phone || process.env.REACT_APP_CONTACT_US_NO}</span> 
                </a>
              </Typography>
            </Box>
          </Box>
          <Box display='flex' flexDirection={windowWidth > 382 ? "row" : "column"} justifyContent={"end"} mt='4px' flexWrap='wrap' >
            <Button
             className='new-modal_responseiq'
              sx={{
                width: {
                  xs: window.innerWidth < 360 ? '100%' : 'auto'
                },
                p: '5px 13px',
                backgroundColor: '#61a4d8',
                maxHeight: '28px',
                mr: { xs: windowWidth < 382 ? '0' : '10px' },
                border: 'none',
                '&:hover': {
                  background: '#2a72a9',
                },
              }}
            >
              <Typography
                variant='subtitle2'
                fontSize='12px'
                noWrap
                color={colors.basics.white}
                fontWeight={800}
              >
                Request a callback
              </Typography>
            </Button>
            <Button
              onClick={() => dispatch(setDialogState({ ...dialogState, emailEnquiryDialog: true }))}
              sx={{
                mt: windowWidth > 382 ? "0" : "5px",
                width: {
                  xs: window.innerWidth < 360 ? '100%' : 'auto'
                },
                p: '5px 13px',
                backgroundColor: '#28bf3f',
                maxHeight: '28px',
                border: 'none',
                '&:hover': {
                  background: '#1d6b23',
                },
              }}
            >
              <Typography
                variant='subtitle2'
                fontSize='12px'
                noWrap
                color={colors.basics.white}
                fontWeight={800}
              >
                Email enquiry
              </Typography>
            </Button>
            <Box ml={1} display='flex' alignItems='center'>
              {/* <IconButton sx={{ p: 0, minWidth: 'auto' }} onClick={() => setCountryChange(true)}>
                <Avatar
                  alt='country flag'
                  src={currentCountry === 'uk' ? UKFlag : IrelandFlag}
                  sx={{ width: 16, height: 16, mr: 0.5 }}
                />
              </IconButton> */}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );

  const headerActionBarDesktop = (
    <Box
      ml='.625rem'
      p='7px 10px'
      height={headerHeight.desktop}
      display={{ xs: 'none', xl: 'flex' }}
      flexDirection='column'
      position='relative'
      justifyContent='space-evenly'
      bgcolor={countryChange ? colors.basics.white : '#ddd'}
    >
      {countryChange ? (
        <Box width='254px' display={'flex'} justifyContent='center' alignItems={'center'} height='100%'>
          <IconButton onClick={handleCountryChange}>
            <Avatar
              alt='country flag'
              src={currentCountry === 'uk' ? IrelandFlag : UKFlag}
              sx={{ width: 16, height: 16, mr: 0.5, borderRadius: 0 }}
            />
          </IconButton>
          <IconButton onClick={() => setCountryChange(false)}>
            <CloseIcon sx={{ color: colors.basics.primary }} />
          </IconButton>
        </Box>
      ) : (
        <>
          <Box display='flex' alignItems='center'>
            <Box sx={{
              '& .call-color': {
                '&:hover': {
                  color: `${colors.basics.primary} !important`,
                },

              },
            }}>
              <Typography fontWeight={500} variant='body1' fontSize='20px'>
              <a href={`tel:${selectedRegion?.phone || process.env.REACT_APP_CONTACT_US_NO}`} rel='nofollow' style={{ textDecoration: 'none' }}>
                  <span className='call-color' style={{ color: '#303030' }}>Call&nbsp;</span>
                  <span className={`${selectedRegion?.ClassName || process.env.REACT_APP_CONTACT_US_NO}`} style={{ color: colors.basics.primary }}>{selectedRegion?.phone || process.env.REACT_APP_CONTACT_US_NO}</span>
                </a>
              </Typography>
              <Typography variant='subtitle2' fontSize='12px'>
                9am-7pm Mon-Fri / 9am-5pm Sat-Sun
              </Typography>
            </Box>
            <Box ml={1}>
              {/* <IconButton sx={{ p: 0, minWidth: 'auto' }} onClick={() => setCountryChange(true)}>
                <Avatar
                  alt='country flag'
                  src={currentCountry === 'uk' ? UKFlag : IrelandFlag}
                  sx={{ width: 16, height: 16, mr: 0.5 }}
                />
                <img
                  src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE4IDI4Ij48cGF0aCBkPSJNMTYuNzk3IDExLjVhLjU0LjU0IDAgMCAxLS4xNTYuMzU5TDkuMzYgMTkuMTRjLS4wOTQuMDk0LS4yMzQuMTU2LS4zNTkuMTU2cy0uMjY2LS4wNjMtLjM1OS0uMTU2bC03LjI4MS03LjI4MWMtLjA5NC0uMDk0LS4xNTYtLjIzNC0uMTU2LS4zNTlzLjA2My0uMjY2LjE1Ni0uMzU5bC43ODEtLjc4MWEuNTA4LjUwOCAwIDAgMSAuMzU5LS4xNTYuNTQuNTQgMCAwIDEgLjM1OS4xNTZsNi4xNDEgNi4xNDEgNi4xNDEtNi4xNDFjLjA5NC0uMDk0LjIzNC0uMTU2LjM1OS0uMTU2cy4yNjYuMDYzLjM1OS4xNTZsLjc4MS43ODFhLjUzNi41MzYgMCAwIDEgLjE1Ni4zNTl6Ii8+PC9zdmc+'
                  alt=''
                />
              </IconButton> */}
            </Box>
          </Box>
          <Box display='flex' mt='4px'>
            <Button
              id="callbackButton"
               className='new-modal_responseiq'
              onClick={handleButtonClick}
              sx={{
                p: '5px 13px',
                backgroundColor: btnClick ? '#2a72a9' : '#61a4d8',
                maxHeight: '28px',
                mr: '10px',
                border: 'none',
                '&:hover': {
                  background: '#2a72a9',
                },
              }}
            >
              <Typography
                variant='subtitle2'
                fontSize='12px'
                noWrap
                color={colors.basics.white}
                fontWeight={800}
              >
                Request a callback
              </Typography>
            </Button>
            <Button
              onClick={() => dispatch(setDialogState({ ...dialogState, emailEnquiryDialog: true }))}
              sx={{
                p: '5px 13px',
                backgroundColor: '#28bf3f',
                maxHeight: '28px',
                border: 'none',
                '&:hover': {
                  background: '#1d6b23',
                },
              }}
            >
              <Typography
                variant='subtitle2'
                fontSize='12px'
                noWrap
                color={colors.basics.white}
                fontWeight={800}
              >
                Email enquiry
              </Typography>
            </Button>
          </Box>
        </>
      )
      }
    </Box >
  );

  return (
    <>
      <AppBar
        position='sticky'
        sx={{
          backgroundColor: colors.basics.white,
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          // zIndex: 1301,
          '&:after': {
            content: '""',
            display: { xs: 'none', xl: 'block' },
            width: 'calc((100vw - 1200px)/2 + 30px)',
            height: '100%',
            position: 'absolute',
            right: '0%',
            top: 0,
            zIndex: -1,
            background: '#ddd',
            cursor: 'pointer',
          },
        }}
      >
        <Container
          sx={{
            maxWidth: '1200px !important',
            width: '100%',
            height: { xs: headerHeight.mobile, lg: headerHeight.desktop },
            display: 'flex',
            alignItems: 'center',
            padding: '.625rem 1.25rem !important',
            // overf  low: 'hidden'
          }}
        >
          <Toolbar disableGutters sx={{ flex: 1, alignItems: 'center', justifyContent: 'space-between' }}>
            <Box
              // mr: { lg: '54px', md: '50px' },
              mr='15px'
              display={{ xs: 'none', xl: 'flex' }}
              minWidth='144.18px'
              height='45.25px'
              alignItems='center'
              mt='-15px'
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setCurrentIndex(5)
                const route = getRoute();
                navigate(route);

                // navigate(`/${window.location.search}`);
              }}
            >
              <Image style={{ width: "100%", height: "100%" }} src={"https://www.americansky.co.uk/FrontEndGlobalAssets/dist/img/brands/americansky.svg"} />
            </Box>
            <Box display={{ xs: 'flex', xl: 'none' }}>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={() => setDirection('left')}
                color='inherit'
                sx={{
                  ml: '-25px',
                  position: 'absolute',
                  top: '-15px',
                  height: '95px',
                  width: '75px',
                  backgroundColor: drawerState?.left ? colors.basics.lightmain : null,
                  borderRadius: '0',
                }}
                disableRipple={true}
              >
                {drawerState?.left ? (
                  <Close />
                ) : (
                  <MenuIcon sx={{ width: '25px', height: '25px', color: colors.basics.black }} />
                )}
              </IconButton>
            </Box>
            <Box
              display={{ xs: 'flex', xl: 'none' }}
              height='45.25px'
              minWidth='144.18px'
              alignItems='center'
              onClick={() => {
                const route = getRoute();
                navigate(route);
              }}
            >
              <Image style={{ width: "100%", height: "100%" }} src={process.env.REACT_APP_LOGO} />
            </Box>

            <Backdrop
              sx={{
                color: colors.basics.white,
                alignItems: 'flex-start',
                top: { xs: headerHeight.mobile, lg: headerHeight.desktop },
              }}
              open={([1, 2].includes(currentIndex) && toggle) || drawerState.left}
              onClick={handleClickAway}
            />
            {navMainMenus.map((item) => {
              return (
                <Box
                  key={item.index}
                  flexGrow={1}
                  display={{ xs: 'none', xl: 'flex' }}
                  justifyContent='center'
                >
                  <Button
                    sx={{
                      display: 'block',
                      p: '0 .625rem',
                      '& h6': {
                        color:
                          item.index === currentIndex && toggle && [1, 2].includes(item.index)
                            ? colors.basics.primary
                            : '#303030',
                      },
                    }}
                    disableRipple={true}
                    variant='text'
                    onClick={() => {
                      setCurrentIndex(item.index);
                      item.index !== currentIndex ? setToggle(true) : setToggle(!toggle);
                    }}
                  >
                    <Typography
                      variant='subtitle1'
                      fontWeight={500}
                      lineHeight='130%'
                      color={colors.grey[10]}
                      whiteSpace='nowrap'
                      onClick={() => {
                        if (item?.link) {
                          window.location.href = item.link
                        }
                      }}
                      sx={{
                        '&:hover': {
                          color: colors.basics.primary,
                        },
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Button>

                  <div hidden={!toggle}>
                    <Box
                      position='absolute'
                      left={0}
                      zIndex={1}
                      width='100%'
                      top={headerHeight.desktop}
                      sx={{ listStyle: 'none' }}
                    >
                      {item.index === currentIndex && item.component}
                    </Box>
                  </div>
                </Box>
              );
            })}

            <Box display='flex' alignItems='center' justifyContent='center'>
              {/* <Box display={{ xs: 'none', xl: 'flex' }} maxWidth='190px'>
                <SearchInput onChange={handleSearch} onSearchButtonClick={() => console.log('click')} />
              </Box>

              <Box display={{ xs: 'flex', xl: 'none' }}>
                <div role='button' style={{ cursor: 'pointer' }} onClick={() => setDirection('right')}>
                  <SearchIcon />
                </div>
              </Box> */}

              {/* <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mr: { xs: '18px', lg: '36px' },
                  ml: { xs: '35px', md: '20px', lg: '50px' },
                }}
              >
                <IconButton sx={{ p: 0 }}>
                  <FavoriteIcon
                    sx={{ width: 35, height: 35, color: colors.basics.primary }}
                  />
                </IconButton>
                <Typography variant='body1' sx={{ fontWeight: 300 }}>
                  (1)
                </Typography>
              </Box> */}
              {Object.keys(userDetails).length > 0 ? (
                <Box flexGrow={0} ml='15px'>
                  <Tooltip title='Open settings'>
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <AccountCircleIcon sx={{ width: 35, height: 35, color: colors.basics.primary }} />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    disableScrollLock={true}
                    sx={{ mt: '45px' }}
                    id='menu-appbar'
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                    PaperProps={{
                      sx: {
                        '&:before': {
                          content: "''",
                          height: '15px',
                          width: '15px',
                          position: 'absolute',
                          backgroundColor: colors.basics.white,
                          top: '-8px',
                          right: '10px',
                          borderTop: `${colors.grey[30]} solid 1px`,
                          borderLeft: `${colors.grey[30]} solid 1px`,
                          transform: 'rotate(45deg)',
                          zIndex: '9',
                        },
                        overflow: 'visible',
                        maxWidth: '200px',
                      },
                    }}
                  >
                    <MenuItem sx={{ backgroundColor: window.location.pathname === "/profile/dashboard" ? colors.basics.pink + " !important" : colors.basics.white }} onClick={() => handleCloseUserMenu('profile/dashboard')}>
                      <Typography textAlign='center'>Dashboard</Typography>
                    </MenuItem>
                    <MenuItem sx={{ backgroundColor: window.location.pathname === '/profile/myaccount' ? colors.basics.pink + " !important" : colors.basics.white }}
                      onClick={() => handleCloseUserMenu('profile/myaccount')}>
                      <Typography textAlign='center'>My Account</Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleCloseUserMenu('/signin')}>
                      <Typography textAlign='center'>Sign out</Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              ) : (
                <Button
                  sx={{ display: 'block', p: '0 .625rem' }}
                  disableRipple={true}
                  variant='text'
                  onClick={() => {
                    setCurrentIndex(4)
                    navigate('/signin')
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    fontWeight={500}
                    lineHeight='130%'
                    color={currentIndex === 4 ? colors.basics.primary : colors.grey[10]}
                    whiteSpace='nowrap'
                    sx={{
                      '&:hover': {
                        color: colors.basics.primary,
                      },
                    }}
                  >
                    Sign in
                  </Typography>
                </Button>
              )}
              {headerActionBarDesktop}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {headerActionBar}
      <LeftDrawer />
      <SearchDrawer onChange={handleSearch} onSearchButtonClick={() => console.log('click')} />
    </>
  );
}
export default Navbar;
