import { Box, Dialog, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDialogState } from '../../features/dialog/dialogSlice';
import { colors } from '../../styles/colors';
import CloseIcon from '@mui/icons-material/Close';
import { SelectInput } from '../../components/SelectInput';
import TextInput from '../../components/TextInput';
import { CustomIconButton } from '../../components/CustomIconButton';
import { ReactComponent as Email } from '../../assets/icons/Email.svg';
import { onlyNumber } from '../../helpers/onlyNumber';
import * as yup from 'yup';
import { CustomeDatePicker } from '../../components/CustomeDatePicker';
import dayjs from 'dayjs';
import { DateObject } from 'react-multi-date-picker';
import { isEmpty } from '../../helpers/isEmpty';
import axios from 'axios';
import { setLoading } from '../../features/loading/loadingSlice';
import TextArea from '../../components/TextArea';
import { getApi, postApi } from '../../utils/api';
import { IE_DEPARTURE, UK_DEPARTURE, US_DEPARTURE } from '../../constants/departure';
import CustomSelectInput from '../../components/CustomSelectInput';
import { useLocation } from 'react-router-dom';

const EmailEnquiryForm = () => {
  //! States
  const location = useLocation();
  const dialogState = useSelector((state: any) => state.dialog.dialogState);
  const { flightRoom } = useSelector((state: any) => state.flightRoom);
  const { userDetails } = useSelector((state: any) => state.userInformation);
  const [date, setDate] = React.useState<any>('');
  const [isButtonClick, setIsButtonClick] = useState(false);
  const [formSend, setFormSend] = React.useState(false);
  const [isInValid, setInvalid] = useState(false)
  const [formData, setFormData] = useState({
    title: "",
    fname: "",
    sname: "",
    email: "",
    pnumber: "",
    // region: {
    //   id: "",
    //   name: ""
    // },
    destination: {
      id: "",
      name: ""
    },
    flexible: "",
    nights: "",
    adults: "0",
    infants: "0",
    childrens: "0",
    childAge: {
      "Child1Age": "1",
      "Child2Age": "1",
      "Child3Age": "1",
      "Child4Age": "1",
      "Child5Age": "1",
      "Child6Age": "1"
    },
    infantAge: {},
    departure: {
      id: "", name: ""
    },
    notes: "",
    date: null,
  })

  const [destinationList, setDestinationList] = useState([{
    name: "Alaska",
    id: "9",
  },{
    name: "America's East Coast",
    id: "1077",
  },
  {
    name: "&nbsp;&nbsp;&nbsp;&nbsp;District of Columbia",
    id: "115",
  },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Washington D.C.", id: "84" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;Illinois", id: "18" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chicago", id: "32" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;New York State", id: "21" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Albany", id: "149" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lake Placid", id: "171" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;New York", id: "60" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;Pennsylvania", id: "63" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gettysburg", id: "939" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Philadelphia", id: "64" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;Virginia", id: "937" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Williamsburg", id: "940" },
  { name: "America's West Coast", id: "1078" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;Arizona", id: "12" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Grand Canyon", id: "106" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lake Powell", id: "49" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Monument Valley", id: "157" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Phoenix", id: "65" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Scottsdale", id: "75" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tucson", id: "81" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;California", id: "13" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Anaheim", id: "28" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Carmel", id: "31" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Coronado", id: "141" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lake Tahoe", id: "50" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Los Angeles", id: "52" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Monterey", id: "55" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Napa Valley", id: "56" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Palm Springs", id: "62" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sacramento", id: "67" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;San Diego", id: "70" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;San Francisco", id: "71" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;San Luis Obispo", id: "1080" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Santa Barbara", id: "72" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Santa Monica", id: "73" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sequoia National Park", id: "112" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sonoma Valley", id: "366" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Yosemite National Park", id: "113" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;Oregon", id: "22" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Portland", id: "66" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;Washington state", id: "26" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Seattle", id: "76" },
  { name: "Massachusetts", id: "7" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;Boston", id: "8" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;Cape Cod", id: "30" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hyannis", id: "125" },
  { name: "New England", id: "11" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;Maine", id: "20" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Acadia National Park", id: "148" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Kennebunkport", id: "45" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Portland Maine", id: "147" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;New Hampshire", id: "151" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;North Conway", id: "152" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;White Mountains", id: "1057" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;Rhode Island", id: "166" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Newport", id: "167" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;Vermont", id: "82" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Stowe", id: "150" },
  { name: "Hawaii", id: "17" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;Hawaii Island", id: "41" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;Honolulu", id: "124" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Waikiki", id: "83" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;Kauai", id: "46" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;Maui", id: "53" },
  { name: "Florida", id: "15" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;Florida Panhandle", id: "1094" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;Gold Coast", id: "1096" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Daytona Beach", id: "1430" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;St Augustine", id: "359" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vero Beach", id: "360" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cocoa Beach", id: "33" },
  { name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fort Lauderdale", id: "38" },
  { id: "1095", name: "&nbsp;&nbsp;&nbsp;&nbsp;Gulf Coast" },
  { id: "1426", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Anna Maria Island" },
  { id: "370", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bonita Springs" },
  { id: "362", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cape Coral" },
  { id: "363", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Captiva" },
  { id: "39", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fort Myers and Sanibel" },
  { id: "279", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Indian Rocks" },
  { id: "280", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Indian Shores" },
  { id: "142", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Marco Island" },
  { id: "57", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Naples" },
  { id: "74", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sarasota" },
  { id: "79", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;St. Pete Clearwater" },
  { id: "282", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tampa" },
  { id: "281", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Treasure Island" },
  { id: "105", name: "&nbsp;&nbsp;&nbsp;&nbsp;Orlando" },
  { id: "1301", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Encore at Reunion" },
  { id: "120", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;International Drive" },
  { id: "48", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Kissimmee" },
  { id: "121", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lake Buena Vista" },
  { id: "265", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Universal Orlando Resort" },
  { id: "264", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Walt Disney World Resort" },
  { id: "117", name: "&nbsp;&nbsp;&nbsp;&nbsp;Miami" },
  { id: "118", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Miami Beach" },
  { id: "78", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;South Beach Miami" },
  { id: "356", name: "&nbsp;&nbsp;&nbsp;&nbsp;Florida Keys" },
  { id: "364", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Islamorada" },
  { id: "143", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Key Largo" },
  { id: "47", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Key West" },
  { id: "1079", name: "America's Rockies &amp; Prairies" },
  { id: "14", name: "&nbsp;&nbsp;&nbsp;&nbsp;Colorado" },
  { id: "34", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Colorado Springs" },
  { id: "36", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Denver" },
  { id: "80", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Grand Junction" },
  { id: "114", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mesa Verde National Park" },
  { id: "1332", name: "&nbsp;&nbsp;&nbsp;&nbsp;Idaho" },
  { id: "1113", name: "&nbsp;&nbsp;&nbsp;&nbsp;Montana" },
  { id: "1331", name: "&nbsp;&nbsp;&nbsp;&nbsp;North Dakota" },
  { id: "153", name: "&nbsp;&nbsp;&nbsp;&nbsp;South Dakota" },
  { id: "154", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mount Rushmore" },
  { id: "25", name: "&nbsp;&nbsp;&nbsp;&nbsp;Utah" },
  { id: "130", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bryce Canyon National Park" },
  { id: "68", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Salt Lake City" },
  { id: "159", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Zion National Park" },
  { id: "27", name: "&nbsp;&nbsp;&nbsp;&nbsp;Wyoming" },
  { id: "43", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Jackson" },
  { id: "938", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Yellowstone National Park" },
  { id: "10", name: "Nevada" },
  { id: "51", name: "&nbsp;&nbsp;&nbsp;&nbsp;Las Vegas" },
  { id: "1070", name: "Deep South" },
  { id: "1256", name: "&nbsp;&nbsp;&nbsp;&nbsp;Alabama" },
  { id: "16", name: "&nbsp;&nbsp;&nbsp;&nbsp;Georgia" },
  { id: "29", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Atlanta" },
  { id: "145", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Savannah" },
  { id: "19", name: "&nbsp;&nbsp;&nbsp;&nbsp;Louisiana" },
  { id: "146", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Baton Rouge" },
  { id: "59", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;New Orleans" },
  { id: "936", name: "&nbsp;&nbsp;&nbsp;&nbsp;Mississippi" },
  { id: "941", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Natchez" },
  { id: "168", name: "&nbsp;&nbsp;&nbsp;&nbsp;North Carolina" },
  { id: "170", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Charlotte" },
  { id: "172", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nags Head" },
  { id: "162", name: "&nbsp;&nbsp;&nbsp;&nbsp;South Carolina" },
  { id: "164", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Charleston" },
  { id: "163", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hilton Head" },
  { id: "165", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Myrtle Beach" },
  { id: "23", name: "&nbsp;&nbsp;&nbsp;&nbsp;Tennessee" },
  { id: "160", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chattanooga" },
  { id: "935", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Great Smoky Mountains" },
  { id: "54", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Memphis" },
  { id: "58", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nashville" },
  { id: "1086", name: "Kentucky" },
  { id: "24", name: "Texas" },
  { id: "158", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Austin" },
  { id: "35", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dallas" },
  { id: "42", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Houston" },
  { id: "69", name: "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;San Antonio" },
  { id: "-1", name: "Any" }
   
   ])
  const [formErrors, setFormErrors] = useState({
    title: "",
    fname: "",
    sname: "",
    email: "",
    pnumber: "",
    date: ""
  })
  const [departure, setDeparture] = useState<any>([])
  const datePickerRef = useRef<any>();
  const dispatch = useDispatch();
  const baseUrl = process.env.REACT_APP_PROJECT_COUNTRY === "ie" ? "https://www.americansky.ie/" : ' https://www.americansky.co.uk';

  const validationSchema = yup.object().shape({
    title: yup
      .string()
      .required("Title is required"),
    fname: yup
      .string()
      .required("First name is required")
      .test(
        "letters-only",
        "First name must contain only letters",
        (value) => {
          if (value && value.length > 0) {
            return /^[A-Za-z][A-Za-z\s]*$/.test(value);
          }
          return true;
        }
      ),
    sname: yup
      .string()
      .min(3, "Last name must be at least 3 characters")
      .test("letters-only", "Last name must contain only letters", (value) => {
        if (value && value.length > 0) {
          return /^[A-Za-z][A-Za-z\s]*$/.test(value);
        }
        return true;
      })
      .required("Last name is required"),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
      pnumber: process.env.REACT_APP_PROJECT_COUNTRY === "ie"
      ? yup.string()
        .nullable()
        .matches(
          /^(\+[0-9]{1,4}[ \-]*)?([0-9]{8,10})$/, // Updated regex pattern for 5 to 10 digits
          "Contact number should be between 8 and 10 digits"
        )
        .required("Contact number is required")
      : yup.string()
        .nullable()
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Contact number is not valid"
        )
        .min(11, "Contact number should be 11 digits")
        .max(11, "Contact number should be 11 digits")
        .required("Contact number is required"),
    date: yup.string().nullable()
      .required("Departure date is required")
  })

  const regionData = [{
    id: "260",
    name: "Africa"
  },
  {
    id: "466",
    name: "Australia"
  },
  {
    id: "175",
    name: "Caribbean"
  },
  {
    id: "433",
    name: "Europe"
  },
  {
    id: "176",
    name: "Far East &amp; Asia"
  },
  {
    id: "177",
    name: "Indian Ocean"
  },
  {
    id: "178",
    name: "Middle East"
  },
  {
    id: "378",
    name: "Polar"
  },
  {
    id: "464",
    name: "South &amp; Central America"
  },
  {
    id: "452",
    name: "South Pacific"
  },
  {
    id: "1",
    name: "Do not know yet"
  }
  ];
  const titles = ["Mr", "Mrs", "Miss", "Ms", "Dr"]

  const flexibleDaysData = [' +/- 0 days', '+/- 3 days', ' +/- 7 days', '1 Month'];
  const nightDurationData = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
  ];



  const tryGetUniqueGoogleCode = useMemo(() => {
    var match = document.cookie.match("(?:^|;)\\s*_ga=([^;]*)"),
      raw = match ? decodeURIComponent(match[1]) : null;
    if (raw) {
      match = raw.match(/(\d+\.\d+)$/);
    }
    return match ? match[1] : null;
  }, []);

  const matchWords = (mainString: string, subString: string) =>
    mainString?.toLowerCase()?.includes(subString?.trim()?.toLowerCase());

  useEffect(() => {
    if (flightRoom) {
      const childAges =
        Array.isArray(flightRoom?.searchCriteria?.child_age) &&
        Object.values(flightRoom?.searchCriteria?.child_age?.flatMap(Object.values))?.reduce(
          (acc: any, value, index) => {
            acc[`Child${index + 1}Age`] = value;
            return acc;
          },
          {}
        );
        const currentDate = flightRoom?.searchCriteria?.date[0];
        const isValidFormat = /^(\d{2})\/(\d{2})\/(\d{4})$/.test(currentDate);
        if (isValidFormat) {
          setDate(currentDate); // No need to format
        } else {
          setDate(dayjs(currentDate).format("DD/MM/YYYY")); // Format into DD/MM/YYYY
        }
      setFormData((prev: any) => ({
        ...prev,
        nights: flightRoom.searchCriteria.night,
        adults: flightRoom.searchCriteria.adults,
        childrens: flightRoom.searchCriteria.childrens,
        infants: flightRoom.searchCriteria.infants,
        date: dayjs(flightRoom?.searchCriteria?.date[0]).format("DD/MM/YYYY"),
        departure: {
          name: flightRoom.searchCriteria.departure.depart_airport_name,
          id: flightRoom.searchCriteria.departure.departure_airport,
        },
        region:
          flightRoom?.searchCriteria &&
          regionData.find((region) =>
            flightRoom?.searchCriteria?.destination?.["Display Name #1"]?.includes(region.name)
          ),
        destination:
          flightRoom?.searchCriteria &&
          destinationList.find((region) =>
            matchWords(flightRoom?.searchCriteria?.destination?.["Display Name #1"]?.trim(), region.name)
          ),
        email: userDetails?.CustomerUser?.email,
        fname: userDetails?.CustomerUser?.first_name,
        sname: userDetails?.CustomerUser?.name,
        ...(flightRoom?.searchCriteria?.childrens > 0
          ? { childAge: childAges }
          : { childAge: formData.childAge }),
        pnumber: userDetails?.CustomerUser?.contact_person ? userDetails.CustomerUser.contact_person : "",
        // notes: `${"https://tropical-v2.netlify.app"}${location.pathname}`, // process.env.REACT_APP_BASE_URL
      }));
    }
  }, [flightRoom]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      date: date,
    }));
  }, [date]);
  //! Functions
  useEffect(() => {
    const currentDate = dayjs();
    const startDate = currentDate.add(1, 'month');
    const date = new DateObject(dayjs(startDate).format("YYYY/MM/DD"))
    // setDate(date)

    const departureArr = process.env.REACT_APP_PROJECT_COUNTRY === "ie" ? IE_DEPARTURE : process.env.REACT_APP_PROJECT_COUNTRY === "us" ? US_DEPARTURE : UK_DEPARTURE
    let list: any = []
    departureArr.map((l) => {
      list.push({
        name: l.depart_airport_name,
        id: l.departure_airport
      })
    })
    setDeparture(list)
  }, [])

  useEffect(() => {
    const handleBodyClick = (event: any) => {
      // event.stopPropagation();
    }

    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.body.removeEventListener('click', handleBodyClick)
    }
  }, [])

  // useEffect(() => {
  //   setFormData({
  //     ...formData,
  //     date: date !== null ? dayjs(date).format('DD/MM/YYYY') : date
  //   })
  //   if (date !== null) {
  //     setFormErrors({
  //       ...formErrors,
  //       date: ""
  //     })
  //   }
  // }, [date])

  // useEffect(() => {
  //   if (formData?.region?.id) {
  //     getDestinationFromRegion(formData?.region?.id);
  //   }
  // }, [formData?.region?.id, dispatch]);

  const getDestinationFromRegion = async (value: any) => {
    const apiUrl = `${baseUrl}/getdestinations?regionId=${value}`
    const response = await getApi(apiUrl)
    let arr: any = []

    if (response !== "error") {
      let data = [...response]
      data.map((d) => {
        arr.push({
          id: d.Value,
          name: d.Text.replace(/\+/g, " ")
        })
        return <></>
      })

      await setDestinationList(arr)
    } else {
      await dispatch(setLoading(false))
    }
    return await arr
  }

  const destinationHandler = (e: any, name: any, value: any) => {
    const filterData = destinationList.filter((f: any) => f.id.toString() === value)
    let val = { ...formData }

    val.destination = {
      id: value,
      name: filterData[0]?.name?.replace(/&nbsp;/g, '').trim()
    }
    setFormData(val)
  }

  const regionChangeHandler = async (e: any, name: any, value: any) => {
    // const { name, value } = e.target;
    dispatch(setLoading(true))
    const filterData = regionData.filter((f: any) => f.id.toString() === value)
    let obj = {
      id: value,
      name: (filterData[0].name)
    }

    let val = { ...formData, [name]: obj }

    const destinationArr = await getDestinationFromRegion(value)
    if (destinationArr.length > 0) {
      val.destination = {
        id: destinationArr[0].id,
        name: destinationArr[0].name
      }
    } else {
      val.destination = {
        id: "",
        name: ""
      }
    }

    setFormData(val)
    await dispatch(setLoading(false))
  }

  const sendInquiry = async (newFormData: any) => {
    dispatch(setLoading(true))
    const apiUrl = `${baseUrl}/addemailenquiry?TrackingCode=`

    const response = await postApi(apiUrl, newFormData)
    if (response !== "error") {
      await setFormSend(true)
      await dispatch(setLoading(false))
    } else {
      await dispatch(setLoading(false))
    }

  }

  const handleSubmit = async (e: any) => {
    setIsButtonClick(true)
    e.preventDefault();
    await validationSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        setInvalid(false)
        setFormErrors({
          title: "",
          fname: "",
          sname: "",
          email: "",
          pnumber: "",
          date: ""
        })

        const newFormData = new FormData()

        newFormData.append("Salutation", formData?.title);
        newFormData.append("FirstName", formData?.fname);
        newFormData.append("LastName", formData?.sname);
        newFormData.append("Email", formData.email);
        newFormData.append("Telephone", formData?.pnumber);
        // newFormData.append("Region", isEmpty(formData.region.id) !== "" ? formData.region.id : "0");
        newFormData.append("Destination", isEmpty(formData?.destination?.id) !== "" ? formData?.destination?.id : "0");
        newFormData.append("CalendarDeptDate", formData?.date || "");
        newFormData.append("Flexible", formData?.flexible !== "" ? formData?.flexible.replace("+/-", " ").split(" ").join("") : "");
        newFormData.append("Duration", formData?.nights);
        newFormData.append("NumberOfAdults", formData?.adults !== "" ? formData?.adults : "0");
        newFormData.append("NumberOfChildren", formData?.childrens !== "" ? formData?.childrens : "0");
        for (const [key, value] of Object.entries(formData?.childAge)) {
          newFormData.append(key, value)
        }
        newFormData.append("PreferredAirport", formData?.departure?.id || "");
        newFormData.append("OtherAirport", "");
        newFormData.append("Requirements", formData?.notes);
        sendInquiry(newFormData)

      })
      .catch((err) => {
        setInvalid(true)
        const errors: any = {};
        err.inner.forEach((e: any) => {
          errors[e.path] = e.message;
        });
        setFormErrors(errors);
      })
  };

  const handleDeparture = (e: any, value: any) => {
    const filterData = departure.filter((f: any) => f.id.toString() === value)
    let val = { ...formData }

    val.departure = {
      id: value,
      name: filterData[0].name || ""
    }
    setFormData(val)
  }

  const handleSetChildAge = (i: number, age: any) => {
    setFormData({
      ...formData,
      childAge: {
        ...formData.childAge,
        [`Child${[i]}Age`]: age
      }
    })
  };

  const handleFormChangeData = async (e: any) => {
    const { name, value } = e.target;
    let val = { ...formData, [name]: value }
    setFormData(val);

    if (isButtonClick) {
      await validationSchema
        .validate(val, { abortEarly: false })
        .then(() => {
          setInvalid(false)
          setFormErrors({
            title: "",
            fname: "",
            sname: "",
            email: "",
            pnumber: "",
            date: ""
          })
        })
        .catch((err) => {
          setInvalid(true)
          const errors: any = {};
          err.inner.forEach((e: any) => {
            errors[e.path] = e.message;
          });
          setFormErrors(errors);
        })
    }
  }

  const handleSelect = async (e: any, name: any, value: any) => {
    let val = { ...formData, [name]: value }
    setFormData(val);

    if (isButtonClick) {
      await validationSchema
        .validate(val, { abortEarly: false })
        .then(() => {
          setInvalid(false)
          setFormErrors({
            title: "",
            fname: "",
            sname: "",
            email: "",
            pnumber: "",
            date: ""
          })
        })
        .catch((err) => {
          setInvalid(true)
          const errors: any = {};
          err.inner.forEach((e: any) => {
            errors[e.path] = e.message;
          });
          setFormErrors(errors);
        })
    }
  }
  const cleanedDestinationName = formData?.destination?.name?.replace(/&nbsp;/g, '')?.trim() || "";
  return (
    <Box id="enquiry-model">
      <Dialog
        id="modalContent"
        open={dialogState.emailEnquiryDialog}
        onClose={() => dispatch(setDialogState({ ...dialogState, emailEnquiryDialog: false }))}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth
        hideBackdrop={false}
        sx={{
          margin: "20px 0px !important",
          '& .MuiDialog-container': {
            height: formSend === true ? 'auto' : '100%',
          },
          '& .Mui-error': {
            marginLeft: "0px",
            marginTop: 0
          },
          "& .MuiPaper-elevation ": {
            maxWidth: "1000px",
            width: "100%"
          },
          '& .MuiDialog-paper': {
            height: '100%',
            maxHeight: '100%',
            p: 0,
            border: 'none',
          }
        }}
      >
        <form onSubmit={handleSubmit}>
          <Box
            display='flex'
            justifyContent='space-between'
            borderBottom='1px solid #dee2e6'
            alignItems={'center'}
            p={'1rem'}
            sx={{
              background: colors.basics.white,
              '& svg path': {
                fill: '#e10f3f',
              },
              '& fieldset': {
                borderRadius: '0 !important',
              },
            }}
          >
            <Typography variant='h1' lineHeight='1.5' fontSize='26px' fontWeight={600}>
              Email enquiry
            </Typography>
            <IconButton
              sx={{ p: 0 }}
              onClick={() => dispatch(setDialogState({ ...dialogState, emailEnquiryDialog: false }))}
              disableRipple
            >
              <CloseIcon
                sx={{
                  color: colors.basics.black,
                  fontSize: '40px',
                }}
              />
            </IconButton>
          </Box>
          {!formSend ? (
            <>
              <Box
                display='flex'
                flexWrap={'wrap'}
                p='1rem'
                sx={{ background: colors.basics.secondary, py: { sm: '40px', xs: '20px' } }}
              >
                {isInValid && <ErrorBox />}

                <Box
                  flex={{ sm: '0 0 50%', xs: '0 0 100%' }}
                  sx={{
                    paddingRight: { sm: '15px', xs: 0 },
                    paddingLeft: { sm: '0px', xs: 0 },
                  }}
                >
                  <Box>
                    <Typography
                      variant='body1'
                      fontSize='1.25rem'
                      lineHeight={'1.25rem'}
                      mb={'1.25rem'}
                      fontWeight={500}
                    >
                      Your Details
                    </Typography>
                  </Box>
                  <Box>
                    <Typography mb={1} variant='subtitle2'>
                    Title *
                    </Typography>
                    <CustomSelectInput
                      ids={"titles"}
                      data={titles}
                      onChange={(e: any, n: any, v: any) => {
                        handleSelect(e, n, v);
                      }}
                      dropdownWidth={"100%"}
                      name={'title'}
                      value={formData.title}
                      multiple={false}
                      checkbox={false}
                      error={formErrors && formErrors.title}
                    />
                  </Box>
                  <Box mt={formErrors.title ? "0px" : "20px"}>
                    <TextInput
                      title='First name *'
                      textStyle={'none !important'}
                      value={formData.fname}
                      onChange={(evt) => handleFormChangeData(evt)}
                      name={`fname`}
                      error={formErrors && formErrors.fname ? true : false}
                      helperText={
                        formErrors && formErrors.fname
                          ? formErrors.fname
                          : ''
                      }
                    />
                  </Box>
                  <Box mt={formErrors.fname ? "25px" : "20px"}>
                    <TextInput
                      title='Last name *'
                      textStyle={'none !important'}
                      value={formData.sname}
                      onChange={(evt) => handleFormChangeData(evt)}
                      name={`sname`}
                      error={formErrors && formErrors.sname ? true : false}
                      helperText={
                        formErrors && formErrors.sname
                          ? formErrors.sname
                          : ''
                      }
                    />
                  </Box>
                  <Box mt={formErrors.sname ? "25px" : "20px"}>
                    <TextInput
                      title='Email *'
                      textStyle={'none !important'}
                      onChange={(evt) => {
                        handleFormChangeData(evt)
                      }}
                      name={`email`}
                      value={formData.email}
                      error={formErrors && formErrors.email ? true : false}
                      helperText={
                        formErrors && formErrors.email ? formErrors.email : ''
                      }
                    />
                  </Box>
                  <Box mt={formErrors.email ? "25px" : "20px"}>
                    <TextInput
                      title='Phone number *'
                      textStyle={'none !important'}
                      onChange={(evt) => {
                        if ((onlyNumber(evt.target.value)) && evt.target.value.length <= 11) {
                          handleFormChangeData(evt)
                        }
                      }}
                      name={`pnumber`}
                      value={formData.pnumber}
                      error={formErrors && formErrors.pnumber ? true : false}
                      helperText={
                        formErrors && formErrors.pnumber
                          ? formErrors.pnumber
                          : ''
                      }
                    />
                  </Box>
                </Box>
                <Box
                  flex={{ sm: '0 0 50%', xs: '0 0 100%' }}
                  sx={{
                    paddingRight: { sm: '0px', xs: 0 },
                    paddingLeft: { sm: '15px', xs: 0 },
                    marginTop: { sm: 0, xs: '20px' },
                  }}
                >
                  <Box>
                    <Typography variant='body1' fontSize='20px' fontWeight={500} mb={'1.25rem'}>
                      Travel Information
                    </Typography>
                  </Box>
                  {/* <Box>
                    <Typography mb={1} variant='subtitle2'>
                      REGION :
                    </Typography>
                    <CustomSelectInput
                      ids="region"
                      idIncluded={true}
                      itemId={formData?.region?.id || ""}
                      data={regionData}
                      onChange={(e: any, n: any, v: any) => {
                        regionChangeHandler(e, n, v);
                      }}
                      name={'region'}
                      value={formData?.region?.name || ""}
                      multiple={false}
                      checkbox={false}
                    />
                  </Box> */}
                  <Box mt={"20px"} sx={{
                    '& .custom-dropdown .dropdown-menu ':{
                      maxHeight:"600px",
                      overflow:"auto"
                    }
                  }}>
                    <Typography mb={1} variant='subtitle2'>
                    Destination :
                    </Typography>
                    <CustomSelectInput
                      ids="destination"
                      data={destinationList}
                      onChange={(e: any, n: any, v: any) => {
                        destinationHandler(e, n, v);
                      }}
                      idIncluded={true}
                      itemId={formData?.destination?.id || ""}
                      name={'destination'}
                      value={cleanedDestinationName}
                      multiple={false}
                      checkbox={false}
                    />
                  </Box>

                  <Box sx={{
                    '.rmdp-container input': {
                      borderColor: formErrors.date && `${colors.basics.red} !important`
                    }
                  }} mt={"20px"} position='relative'>
                    <Typography mb={"10px"} variant='subtitle2'>
                    Departure date* :
                    </Typography>

                    <CustomeDatePicker
                      initialDates={null}
                      datePickerRef={datePickerRef}
                      setDate={(e) => {
                        setDate(e);
                      }}
                      isButtonShown={false}
                      date={date}
                      numberOfMonths={1}
                      range={false}
                      format={'DD/MM/YYYY'}
                    />
                    <Typography
                      variant='h4'
                      mb={"5px"}
                      sx={{ color: colors.basics.red, fontSize: '12px', lineHeight: 1.5 }}
                      className='MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained Mui-required css-1de7kq0-MuiFormHelperText-root'
                    >
                      {formErrors && formErrors.date
                        ? formErrors.date
                        : ''}
                    </Typography>
                  </Box>

                  <Box mt={"20px"}>
                    <Typography mb={"10px"} variant='subtitle2'>
                    Flexible by :
                    </Typography>
                    <CustomSelectInput
                      ids="flexible"
                      data={flexibleDaysData}
                      onChange={(e: any, n: any, v: any) => {
                        handleSelect(e, n, v);
                      }}
                      name={'flexible'}
                      value={formData.flexible}
                      multiple={false}
                      checkbox={false}
                    />
                  </Box>
                  <Box mt={"20px"}  >
                    <Typography mb={"10px"} variant='subtitle2'>
                    Duration (nights) :
                    </Typography>
                    <CustomSelectInput
                      ids="nights"
                      data={nightDurationData}
                      onChange={(e: any, n: any, v: any) => {
                        handleSelect(e, n, v);
                      }}
                      name={"nights"}
                      value={formData.nights}
                      multiple={false}
                      checkbox={false}
                    />
                  </Box>
                  <Box mt={"20px"}>
                    <Typography mb={1} variant='subtitle2'>
                    Preferred departure airport :
                    </Typography>
                    <CustomSelectInput
                      data={departure}
                      ids="departures"
                      idIncluded={true}
                      onChange={(e: any, n: any, v: any) => handleDeparture(e, v)}
                      itemId={formData?.departure?.id || ""}
                      value={formData?.departure?.name || ""}
                      name={'departure'}
                      multiple={false}
                      checkbox={false}
                    />
                  </Box>
                  <Box mt={2}>
                    <Box>
                      <Typography mb={"10px"} variant='subtitle2'>
                      No. of people :
                      </Typography>
                    </Box>

                    <Grid container columnSpacing={5}>
                      <Grid item xs={6} md={4}>
                        <Box>
                          <Typography mb={1} variant='subtitle2'>
                          Adults
                          </Typography>
                          <CustomSelectInput
                            data={[...Array(11)].map((el, i) => String(i))}
                            onChange={(e: any, n: any, v: any) => {
                              handleSelect(e, n, v);
                            }}
                            name={'adults'}
                            ids="adults"
                            value={(formData.adults)}
                            multiple={false}
                            checkbox={false}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={6} md={4}>
                        <Box>
                          <Typography mb={1} variant='subtitle2'>
                          Children
                          </Typography>
                          <CustomSelectInput
                            data={[...Array(7)].map((el, i) => String(i))}
                            onChange={(e: any, n: any, v: any) => {
                              handleSelect(e, n, v);
                            }}
                            ids="childrens"
                            name={'childrens'}
                            value={formData.childrens}
                            multiple={false}
                            checkbox={false}
                          />
                        </Box>
                      </Grid>
                    </Grid>

                    <Box>
                      {(formData.childrens === '0' || formData.childrens === '') ? null : formData.childrens ? (
                        <Box>
                          <Typography mt={2} variant='subtitle2'>
                          Child ages : *
                          </Typography>
                        </Box>
                      ) : (
                        ''
                      )}

                      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {Array.from({ length: Number(formData.childrens) }, (item, i) => (
                          <Box
                            sx={{
                              mr: "5%",
                              mt: "2%",
                              '& .MuiInputBase-root': {
                                width: '100%',
                                minWidth: "100px",
                                maxHeight: '37px !important',
                              },
                              '& .MuiSelect-select': {
                                '&>em': {
                                  fontFamily: 'Raleway',
                                  fontStyle: 'normal',
                                  color: '#9e9e9e',
                                  fontWeight: 500,
                                },
                                fontSize: "14px"
                              },
                              // '& .MuiBox-root': {
                              //   right: "4px",
                              // },
                            }}
                          >
                            <CustomSelectInput
                              boxStyle={{ minWidth: "100px" }}
                              data={[...Array(16)].map((el, i) => String(i + 1))}
                              onChange={(e: any, n: any, v: any) => handleSetChildAge(i + 1, v)}
                              value={((formData?.childAge as any)["Child" + String(i + 1) + "Age"] || '')}
                              placeholder={`Child ${i + 1} Age`}
                              ids={`child-age${i}`}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box width={"100%"} mb={"40px"} mt='10px'>
                  <Typography mb={1} mt={2} variant='subtitle2'>
                    ADDITIONAL INFORMATION :
                  </Typography>
                  <TextArea value={formData.notes}
                    name="notes"
                    minRow={2}
                    // maxRow={7}
                    style={{
                      width: "100%",
                      height: "100%"
                    }}
                    onChange={(e: any) => handleFormChangeData(e)}
                    placeholder='To help us tailor a quote for you, please let us know any additional information such as; particular resorts and hotels that you wish to stay at? Are you travelling for a wedding, honeymoon or anniversary? Do you have an approximate budget?' />
                </Box>
              </Box>
              <Box
                borderTop='1px solid #dee2e6'
                p={'16px'}
                sx={{
                  background: colors.basics.white, '& .MuiInputBase-input': { p: '7px' }
                }}
              >
                <Box display={'flex'} justifyContent={'flex-end'}>
                  <Box>
                    <Typography mb={1} mt={2} mr={1} variant='subtitle2' fontSize={'12px'}>
                      *Required Fields
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      '& Button': {
                        bgcolor: colors.basics.primary,

                        '&:hover , &:focus': {
                          bgcolor: '#cc2600 !important',

                          '&:hover': {
                            color: colors.basics.white, '& .MuiInputBase-input': { p: '7px' },
                            '& svg': {
                              '& path': {
                                pr: '1px',
                                fill: colors.basics.white, '& .MuiInputBase-input': { p: '7px' }
                              },
                            },
                          },

                          '& .footerIcon': {
                            bgcolor: colors.basics.primary,
                          },
                        },

                        '& .FooterButton , & .footerIcon': {
                          bgcolor: 'transparent',
                        },
                        '& .FooterButton': {
                          flex: '0 0 65%',
                        },
                        '& .footerIcon': {
                          flex: '0 0 35%',
                        },
                      },
                    }}
                  >
                    <CustomIconButton
                      icon={<Email />}
                      className='FooterButton'
                      IconClassName='footerIcon'
                      type='submit'
                      label={
                        <Box display='flex' alignItems='center' p='3px 0px 3px 0px'>
                          <Box>
                            <Typography variant='body2' color={colors.basics.white} fontFamily='sans-serif'>
                              Send
                            </Typography>
                          </Box>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <Box>
              <Box
                sx={{
                  width: '100%',
                  p: '20px',
                  '& .MuiDialog-container': {
                    height: '20% !important',
                  },
                  '& .MuiDialog-paper': {
                    width: 'auto',
                  },
                }}
              >
                <Box
                  sx={{
                    background: colors.basics.secondary,
                    textAlign: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography fontSize={{ sm: '16px', xs: '14px' }}>
                    <strong>Thank you.</strong>You have successfully sent your enquiry
                  </Typography>
                </Box>
              </Box>{' '}
              <Box
                borderTop='1px solid #dee2e6'
                p='1rem'
                sx={{
                  background: colors.basics.white, '& .MuiInputBase-input': { p: '7px' }
                }}
              ></Box>
            </Box>
          )}
        </form>
      </Dialog>
    </Box >
  );
};

export default EmailEnquiryForm;

export const ErrorBox = () => {
  return (
    <Box bgcolor={colors.basics.primary} width='100%' p='20px' mb='20px'>
      <Typography
        variant='body1'
        color={colors.basics.white}
        fontWeight={600}
        className='eefFeedback alert alert-danger'
      >
        Sorry, we could not send your enquiry. Please complete all required fields.
      </Typography>
    </Box>
  );
};
