import React from 'react';
import { BaseTextFieldProps, Box, SxProps, TextField, Typography, InputAdornment } from '@mui/material';
import { colors } from '../styles/colors';

interface TextInputProps extends BaseTextFieldProps {
  title?: string;
  textFieldStyle?: SxProps;
  textStyle?:any;
  style?: React.CSSProperties | any;
  type?: string;
  onChange?: (e: any) => void;
  value?: any;
  inputProps?: any;
  error?: any;
  helperText?: any;
  name?: string;
  placeholder?: string;
  required?: boolean;
  children?: JSX.Element;
  isFull?: boolean;
  select?: boolean;
  disabled?: boolean;
  EndIcon?: any;
  autoComplete?: any;
}

export const TextInput = ({
  title,
  textStyle,
  textFieldStyle,
  style,
  type,
  onChange,
  value,
  error,
  inputProps,
  helperText,
  name,
  placeholder,
  required,
  children,
  select,
  autoComplete,
  disabled,
  EndIcon,
}: TextInputProps) => {
  return (
    <Box sx={{ ...style }}>
      {title && (
        <Typography variant='subtitle2' color={colors.grey[10]} mb='8px' textTransform={textStyle ? textStyle : 'uppercase'}>
          {title}
        </Typography>
      )}
      <TextField
        autoComplete={autoComplete}
        sx={{ ...textFieldStyle }}
        type={type || 'text'}
        onChange={(e) => onChange && onChange(e)}
        value={value}
        error={error}
        helperText={helperText}
        name={name}
        placeholder={placeholder}
        required={required}
        inputProps={inputProps}
        select={select}
        disabled={disabled}
        InputProps={{
          readOnly: false,
          endAdornment: EndIcon ? (
            <InputAdornment position='end' sx={{ p: '1px' }}>
              <Box display='flex'>{EndIcon}</Box>
            </InputAdornment>
          ) : (
            <></>
          ),

        }}
      >
        {children}
      </TextField>
    </Box>
  );
};

export default TextInput;
